import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import User from "@/views/User";
import Users from "@/views/Users";
import Login from "@/views/Login"
import Code from "@/views/Code"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: Login,
    component: Login
  },
  {
    path: '/code',
    name: 'Code',
    component: Code,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:id',
    name: 'User',
    component: User,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('authToken')
  if (to.path !== '/login' && !loggedIn) {
    next('/login')
  } else if (to.path === '/login' && loggedIn) {
    next('/users')
  } else {
    next()
  }
  next()
})

export default router
