<template>
<div class="home">
  <v-card-title class="text-center font-weight-black" max-height="600" style="font-size: 36px">Добро пожаловать в панель статистики</v-card-title>

</div>
</template>

<script>
export default {
name: "Welcome"
}
</script>

<style scoped>

</style>