var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Все пользователи")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.usersData,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"hide-default-footer":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.reformatDate(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":['TRIAL', 'ADVANCED', 'UNPAID'],"label":"Статус","solo":"","dense":"","outlined":""},on:{"change":function($event){return _vm.updateProfile(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.subscribedTo",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatForDisplay(item.subscribedTo),"label":"Оплачен до","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.updateProfile(item)}},model:{value:(item.subscribedTo),callback:function ($$v) {_vm.$set(item, "subscribedTo", $$v)},expression:"item.subscribedTo"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":item.tgId ? { name: 'User', params: { id: item.tgId } } : null,"disabled":!item.tgId}},[_c('v-icon',[_vm._v("mdi-page-next")])],1)]}}])}),_c('v-row',{staticClass:"mt-3 mb-3",attrs:{"justify":"end","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemsPerPage),expression:"itemsPerPage"}],staticClass:"custom-select mr-3",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.itemsPerPage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.fetchUsers]}},_vm._l(([5, 10, 15, 30, 50, 100]),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0),_c('v-pagination',{staticClass:"mr-3",attrs:{"length":_vm.pageCount,"size":"small"},on:{"input":_vm.fetchUsers},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('span',{staticClass:"caption larger-caption"},[_vm._v(_vm._s(_vm.pageStart)+"-"+_vm._s(_vm.pageEnd)+" из "+_vm._s(_vm.totalItems))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }