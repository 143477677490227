<template>
  <div class="">
    <div v-if="loading">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <div v-else-if="user">
      <v-card class="mt-5">
        <v-card-title>Telegram id: {{ user_id }}</v-card-title>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="userHistory"
          :items-per-page="20"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="elevation-1"
          multi-sort
        >
          <template v-slot:item.index="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ reformatData(item.createdAt) }}
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-else>
      <v-alert border="top" color="red lighten-2" dark>
        Такого пользователя не существует
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      headers: [
        {
          text: "№",
          value: "index",
          sortable: false,
        },
        { text: "Введенное сообщение", value: "message" },
        { text: "Дата ввода сообщения", value: "createdAt" },
      ],
      user_id: null,
      user: null,
      userHistory: [],
      loading: true,
      user_tgId: null,
      sortBy: ["createdAt"],
      sortDesc: [true],
    };
  },
  methods: {
    loadHistory: function() {
      this.$axios
        .get(`/telegram/profile/${this.user_id}/history`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then(({ data }) => (this.userHistory = data))
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    reformatData: function(data) {
      return new Date(data).toLocaleString();
    },
  },
  mounted: function() {
    this.user_id = this.$route.params.id;
    this.$axios
      .get(`/telegram/profile/${this.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(({ data }) => {
        if (data.id) {
          this.user = data;
          this.user_tgId = data.tgId;
          this.loadHistory();
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally();
  },
};
</script>
