<template>
  <v-card class="mt-5">
    <v-card-title>Все пользователи</v-card-title>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="usersData"
      :items-per-page="itemsPerPage"
      :page="page"
      hide-default-footer
      multi-sort
      class="elevation-1"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ reformatDate(item.createdAt) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-select
          :items="['TRIAL', 'ADVANCED', 'UNPAID']"
          v-model="item.status"
          @change="updateProfile(item)"
          label="Статус"
          solo
          dense
          outlined
        />
      </template>
      <template v-slot:item.subscribedTo="{ item }">
        <v-menu
          ref="menu"
          v-model="item.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatForDisplay(item.subscribedTo)"
              label="Оплачен до"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="item.subscribedTo" @input="updateProfile(item)" />
        </v-menu>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          color="primary"
          :to="item.tgId ? { name: 'User', params: { id: item.tgId } } : null"
          :disabled="!item.tgId"
        >
          <v-icon>mdi-page-next</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-row justify="end" align="center" class="mt-3 mb-3">
      <v-col cols="auto" class="d-flex align-center">
        <select v-model="itemsPerPage" @change="fetchUsers" class="custom-select mr-3">
          <option v-for="option in [5, 10, 15, 30, 50, 100]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>

        <v-pagination
          v-model="page"
          :length="pageCount"
          @input="fetchUsers"
          size="small"
          class="mr-3"
        />
        <span class="caption larger-caption">{{ pageStart }}-{{ pageEnd }} из {{ totalItems }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      headers: [
        { text: "ID", align: "start", value: "id", sortable: true },
        { text: "Telegram ID", value: "tgId", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "ТГ Логин", value: "tgUsername", sortable: true },
        { text: "ТГ Имя", value: "tgFirstName", sortable: true },
        { text: "ТГ Фамилия", value: "tgLastName", sortable: true },
        { text: "Имя", value: "firstName", sortable: true },
        { text: "Фамилия", value: "lastName", sortable: true },
        { text: "Дата регистрации", value: "createdAt", sortable: true },
        { text: "Статус", value: "status", sortable: true },
        { text: "Оплачен до", value: "subscribedTo", sortable: true },
        { text: "Действия", value: "actions", sortable: false },
      ],
      usersData: [],
      loading: false,
      page: 1,
      itemsPerPage: 5,
      totalItems: 0,
      pageCount: 1,
      dialog: false,
      userDetail: null,
    };
  },
  computed: {
    pageStart() {
      return (this.page - 1) * this.itemsPerPage + 1;
    },
    pageEnd() {
      return Math.min(this.page * this.itemsPerPage, this.totalItems);
    }
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      try {
        const params = {
          page: this.page,
          take: this.itemsPerPage,
        };

        const result = await this.$axios.get("/users", {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });

        if (result.data && result.data.results) {
          this.usersData = result.data.results.map((user) => ({
            ...user,
            subscribedTo: this.prepareDateForPicker(user.subscribedTo),
            menu: false,
          }));
        }

        const meta = result.data.meta;
        if (meta) {
          this.totalItems = meta.itemCount || 0;
          this.page = meta.page || 1;
          this.itemsPerPage = meta.take || 5;
          this.pageCount = meta.pageCount || 1;
        }
      } catch (error) {
        console.error("Ошибка при загрузке пользователей:", error);
      } finally {
        this.loading = false;
      }
    },

    reformatDate(data) {
      return new Date(data).toLocaleString();
    },
    formatForDisplay(date) {
      if (!date) return "Дата не указана";
      return new Date(date).toLocaleDateString();
    },
    prepareDateForPicker(dateStr) {
      const date = new Date(dateStr);
      if (!this.isValidDate(date)) {
        return new Date().toISOString().substr(0, 10);
      }
      return date.toISOString().substr(0, 10);
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },

    async updateProfile(item) {
      try {
        const updateUserDto = {
          status: item.status,
          subscribedTo: item.subscribedTo ? new Date(item.subscribedTo) : null,
        };

        const response = await this.$axios.patch(
          `/users/${item.id}`,
          updateUserDto,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        console.log("User updated successfully:", response.data);
      } catch (error) {
        console.error("Failed to update user:", error);
      }
    },
  },
  watch: {
    page() {
      this.fetchUsers();
    },
    itemsPerPage() {
      this.fetchUsers();
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
.larger-caption {
  font-size: 16px;
}

.mr-3 {
  margin-right: 16px;
}

.custom-select {
  height: 40px;
  font-size: 16px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.caption.larger-caption{
  margin-right: 10px;
}
</style>
