<template>
    <div class="promocode-container">
      <p class="title">ПРОМОКОДЫ</p>
      <form @submit.prevent="handleSubmit" class="promocode-form">
        <input
          v-model="promocode.promocode"
          placeholder="Промокод"
          class="input-field"
        />
        <input
          v-model.number="promocode.discount"
          placeholder="Скидка (%)"
          class="input-field"
          type="number"
          min="0"
          max="100"
        />
        <button type="submit" class="submit-button">Сохранить</button>
      </form>
  
      <ul class="promocode-list">
        <li
          v-for="code in promocodes"
          :key="code.id"
          :class="{ editing: editingPromocodeId === code.id }"
          class="promocode-item"
        >
          <span>{{ code.promocode }} - Скидка: {{ code.discount }}%</span>
          <button @click="editPromocode(code)" class="edit-button">
            Редактировать
          </button>
          <button @click="deletePromocode(code.id)" class="delete-button">
            Удалить
          </button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Code',
    data() {
      return {
        editingPromocodeId: null,
        promocodes: [],
        promocode: {
          id: null,
          promocode: "",
        },
      };
    },
    mounted() {
      this.fetchPromocodes();
    },
    methods: {
      async fetchPromocodes() {
        await this.$axios
          .get("/promocode", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then((response) => {
            this.promocodes = response.data;
          })
          .catch((error) => {
            console.error("Ошибка при получении промокодов:", error);
          });
      },
      handleSubmit() {
        if (this.promocode.id) {
          this.updatePromocode();
        } else {
          this.createPromocode();
        }
        this.editingPromocodeId = null;
      },
      createPromocode() {
        this.$axios
          .post("/promocode", this.promocode, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then(() => {
            this.fetchPromocodes();
          })
          .catch((error) => {
            console.error("Ошибка при создании промокода:", error);
          });
      },
      editPromocode(code) {
        this.promocode = { ...code };
        this.editingPromocodeId = code.id;
      },
      updatePromocode() {
        this.$axios
          .put(`/promocode/${this.promocode.id}`, this.promocode, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          })
          .then(() => {
            this.fetchPromocodes();
            this.promocode = { id: null, promocode: "" };
          })
          .catch((error) => {
            console.error("Ошибка при обновлении промокода:", error);
          });
      },
      async deletePromocode(id) {
        await this.$axios
          .delete(`/promocode/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }).then(() => {
            this.fetchPromocodes();
          })
          .catch((error) => {
            console.error("Ошибка при удалении промокода:", error);
          });
      },
    },
  };
  </script>
  
  <style>
  .promocode-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 24px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .promocode-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .input-field {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submit-button,
  .edit-button,
  .delete-button {
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
  }
  
  .edit-button {
    background-color: #17a2b8;
    color: white;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .promocode-list {
    margin-top: 20px;
  }
  
  .promocode-item {
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .promocode-item span {
    flex: 1;
    margin-right: 10px;
  }
  
  .promocode-item button {
    white-space: nowrap;
    margin-left: 5px;
  }
  
  .editing {
    animation: pulseAnimation 1s infinite;
    border-color: #17a2b8;
  }
  
  @keyframes pulseAnimation {
    0% {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(23, 162, 184, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0);
    }
  }
  </style>
  