<template>
  <div class="login-container">
    <h1>Sign In</h1>
    <form @submit.prevent="login" class="login-form">
      <input v-model="email" placeholder="Email" class="login-input"/>
      <input v-model="password" type="password" placeholder="Password" class="login-input"/>
      <button type="submit" class="login-button">Login</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
    }
  },
  methods: {
    async login() {
      try {
        const response = await this.$axios.post('/auth/login/', {
          email: this.email,
          password: this.password 
        })
       
        localStorage.setItem('authToken', response.data.accessToken)
        this.$router.push('/users')
      } catch (error) {
        console.error('There was an error!', error);
        this.errorMessage = 'Неверный логин или пароль';
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* background-color: #89CFF0; A shade of blue */
  color: #000;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 10px; /* Space between elements */
}

.login-input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  padding: 10px;
  background-color: #1E90FF;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

/* Styling for hover effect on button */
.login-button:hover {
  background-color: #4169E1; /* Even darker blue */
}
</style>